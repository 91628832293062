table {
  &.vertical-align-middle {
    tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
  thead{
    tr {
      &.sortable th {
        a{
          text-decoration: none;
          color: $text-color;
          &.active{
            color: $brand-primary;
          }
        }
      }
    }
  }
  tbody{
    tr{
      &.deleted{
        td:not(.delete){
          opacity: 0.5;
          pointer-events: none;
        }
      }
      &.divider{
        td:not(.edit):not(.delete){
          opacity: 0;
        }
      }
      &.comment-box-row{
        background: $brand-primary !important;
        color: $white;
      }
      td{
        .glyphicon{
          cursor: pointer;
          color: $brand-primary;
        }
        a{
          text-decoration: none !important;
          color: $brand-primary;
          &:hover{
            color: $brand-info;
          }
        }
        &.divider{
          background: $brand-info !important;
        }
      }
    }
  }
}
.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.mt-10{
  margin-top: 10px;
}

.ml-10{
  margin-left: 10px;
}

.mr-10{
  margin-right: 10px;
}

.mb-10{
  margin-bottom: 10px;
}

.mt-20{
  margin-top: 20px;
}

.ml-20{
  margin-left: 20px;
}

.mr-20{
  margin-right: 20px;
}

.mb-20{
  margin-bottom: 20px;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.pt-10{
  padding-top: 10px;
}

.pl-10{
  padding-left: 10px;
}

.pr-10{
  padding-right: 10px;
}

.pb-10{
  padding-bottom: 10px;
}

.pt-20{
  padding-top: 20px;
}

.pl-20{
  padding-left: 20px;
}

.pr-20{
  padding-right: 20px;
}

.pb-20{
  padding-bottom: 20px;
}
@media screen and (min-width: $screen-sm-min){
  .navbar-nav{
    &.navbar-center{
      float: none;
      width: auto;
      margin: 0 auto;
      display: inline-block;
      li{
        display: inline-block
      }
    }
  }
}

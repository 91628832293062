// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-secondary: #3f3f3f;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;
$dark-grey: #5f5f5f;
$medium-grey: #8f8f8f;
$light-grey: #dfdfdf;
$white: #fff;
$black: #000;

// Typography
$icon-font-path: "//cdnjs.cloudflare.com/ajax/libs/bootswatch/3.3.7/fonts/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;
$gray-dark: #3f3f3f;
$gray: #7F7F7F;
$gray-light: #AFAFAF;
$gray-lighter: #DfDfDf;

$theme-colors: (
  primary: $brand-primary,
  secondary: $gray-dark,
  success: $brand-success,
  info: $brand-info,
  warning: $brand-warning,
  danger: $brand-danger,
  light: $gray-light,
  dark: $gray-dark
);

// Navbar
$navbar-default-bg: #fff;
$tab-panel-border: #ddd;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-color: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);
$input-disabled-bg: transparent;
$form-check-input-gutter: 20px;
$form-feedback-invalid-color: $gray;

// Panels
$panel-default-heading-bg: #fff;

//FontAwesome
$fa-font-path: "//netdna.bootstrapcdn.com/font-awesome/4.7.0/fonts" !default;

#app {
  margin-bottom: 40px;
}

.divider {
  &.primary {
    border-color: $brand-primary
  }
}

.bg-grey, .bg-medium-grey, .btn-grey, .btn-medium-grey{
  background: $medium-grey;
  color: $white;
}

.bg-dark-grey, .btn-dark-grey{
  background: $dark-grey;
  color: $white;
}

.bg-light-grey, .btn-light-grey{
  background: $light-grey;
  color: $black;
}

.btn-grey, .btn-light-grey, .btn-medium-grey, .btn-dark-grey{
  border-color: $black;
}

form, .panel, .modal-body {
  position: relative;
  .processing-container {
    display: none;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1002;
    .processing-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &.processing {
    .processing-container {
      display: block;
    }
  }
}

ul.selectable-list{
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  min-height: 100px;
  ul.action-list{
    float: right;
    display: inline-block;
    li{
      text-align: center;
      color: $text-color;
      display: inline-block;
      margin: 0 5px;
    }
  }
  li{
    list-style-type: none;
    color: $text-color;
    cursor: pointer;
    .title{
      line-height: 28px;
    }
    &.active{
      background-color: $btn-primary-bg;
      color: $btn-primary-color;
      font-weight: 800;
      input{
        color: $gray-dark;
      }
      ul.action-list{
        li{
          line-height: 28px;
          color: $btn-primary-color;
        }
      }
    }
  }
  & > li{
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.multiselect--active{
  z-index: 1002 !important;
}

.text-white{
  color: $white !important;
}

.text-black{
  color: $black !important;
}

.color-inherit{
  color: inherit !important;
}

textarea.no-resize{
  resize: none;
}

.no-vertical-align{
  vertical-align: unset !important;
}

.no-box-shadow{
  box-shadow: none !important;
}

.center-items{
  display: flex;
  align-items: center;
  justify-content: center;
}
#app-documents {
  .doc-group {
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
    .doc-title {
      position: relative;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      word-break: break-word;
      border: 1px solid $brand-info;
      border-radius: 4px 0 0 4px;
      font-size: 12px;
      a{
        text-decoration: none !important;
      }
      &.active{
        border-color: darken($brand-success, 10%);
        background-color: $gray-dark;
        color: lighten($gray-light, 10%);
        font-weight: 900;
        a{
          color: lighten($gray-light, 10%);
        }
      }
      &.bg-success{
        &:after{
          content: '\f05d';
          font-family: "FontAwesome", sans-serif;
          font-size: 14px;
          top: 0;
          right: 10px;
          position: absolute;
          color: $brand-success;
        }
      }
    }
    .input-group-btn{
      .btn-group-vertical{
        button{
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

#app-object-documents{
  .linked-doc{
    .doc-title{
      display: block;
      width: 100%;
      height: auto;
      padding: 6px 12px;
      line-height: 1.6;
      border: 1px solid $gray-light;
      border-radius: 4px;
      padding-right: 45px;
    }
  }
}
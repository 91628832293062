.tab-content{
  margin-bottom: 20px;
  .tab-pane{
    &.active{
      padding: 20px;
      border: 1px solid $tab-panel-border;
      background-color: $body-bg;
      border-top: 0;
    }
  }
}
.form-box {
  margin-top: 35px;
  .form-top {
    overflow: hidden;
    padding: 0 25px 15px 25px;
    background: #fff;
    -moz-border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    text-align: left;
  }
  .form-top-left {
    float: left;
    width: 75%;
    padding-top: 0;
    h3 {
      margin-top: 0;
    }
  }
  .form-top-right {
    float: left;
    width: 25%;
    padding-top: 10px;
    font-size: 66px;
    color: #ddd;
    line-height: 66px;
    text-align: right;
  }
  .form-bottom {
    padding: 25px 25px 30px 25px;
    background: #eee;
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    text-align: left;
    form {
      textarea {
        height: 100px;
      }
      button.btn {
        width: 100%;
      }
    }
  }
}

.multiselect {
  min-height: 0;
  height: 36px;
}

.form-control.checkbox{
  height: 27px;
}

.form-control-feedback {
  & + .form-control-feedback {
    right: 36px;
  }
  &.clickable{
    cursor: pointer;
    pointer-events: auto;
  }
}

.checkbox {
  &.abc-checkbox {
    label {
      &:before, &:after {
        top: 0;
      }
    }
  }
}

form.confirm-action-inline{
  position: absolute;
  right: 14px;
  top: 0;
  z-index: 5;
}